@import 'src/styles/variables';

.list {
  display: flex;
  flex: 0 0 auto;
  margin: -15px;
  flex-wrap: wrap;
  justify-content: center;
}

.listSingle {
  // None
}

.item {
  width: 365px;

  @include for-size(phone-only) {
    width: 100%;
    max-width: none;
  }
}

.itemSingle {
  flex-grow: 0;

  @include for-size(tablet-portrait-up) {
    width: 600px;
  }
}

.more {
  display: flex;
  justify-content: center;
  margin: 20px 0px 0px 0px;
}
