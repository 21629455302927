@import 'src/styles/variables';

.item {
  width: 350px;
  // max-width: 500px;

  @include for-size(phone-only) {
    width: 100%;
    max-width: none;
  }
}

.story {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 15px;
  background: $white;
  border-radius: 5px;
  text-decoration: none;
  transition: transform 0.3s $cubic;
  z-index: $z-base;
  border: 0;

  a {
    text-decoration: none;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: 0.2s;
    z-index: $z-behind;
    mix-blend-mode: multiply;
    box-shadow: $shadow-off;
  }

  &:hover {
    transform: translateY(-2px);

    .title {
      text-decoration: underline;
    }

    &:before {
      box-shadow: $shadow-on;
    }

    .moreArrow {
      margin-left: 10px;
    }
  }
}

.content {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  color: $darkblue;
  // font-family: $publicsans;
  font-size: 24px;
  line-height: 1.35;
  font-weight: 700;
  margin-bottom: 10px;

  @include for-size(phone-only) {
    font-size: 22px;
    font-weight: 600;
  }
}

.author {
  display: flex;

  align-items: center;
  margin-right: 20px;
}

.name {
  // margin-right: 10px;
  // font-family: $publicsans;
  font-weight: 800;
  // text-transform: uppercase;
}

.text {
  color: $gray-80;
  margin-bottom: 10px;

  @include for-size(phone-only) {
    max-height: 78px;
    overflow: hidden;
  }
}

.image {
  width: 100%;
  margin: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 180px;
  object-fit: cover;
}

.imageBackground {
  width: 100%;
  margin: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 180px;
  background-size: cover;
  background-position: center;
}

.byline {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: $dark-gray-shadow;
}

.tags {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin: -12px 15px;
  flex-wrap: wrap;
  overflow-y: hidden;
  height: 24px;
  z-index: $z-label;
}

.tag {
  background-color: $darkblue;
  color: $white;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: 500;
  white-space: nowrap;
  // overflow: hidden;
  padding: 0 8px;
  margin: 0 5px;
}

.more {
  margin-top: auto;
  vertical-align: middle;

  .moreText {
    text-decoration: none;
    color: $darkblue;
    font-weight: 500;
    font-size: 14px;
  }

  .moreArrow {
    font-size: 14px;
    text-decoration: none;
    color: $darkblue;
    margin-left: 5px;
    transition: all 0.3s $cubic;
  }

  &:hover {
    .moreText {
      text-decoration: underline;
    }
  }
}

.latest {
  margin: 10px 0 30px 0;
}
